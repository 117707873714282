import { useRemoveGoogleTestimonial } from '@/hooks/features/useRemoveGoogleTestimonial'

import { Loader } from '../global/Loader/Loader'
import TestimonialSlider, { TestimonialSliderProps } from './TestimonialSlider'

const TestimonialSliderWrapper = (props: TestimonialSliderProps) => {
  const { isLoading } = useRemoveGoogleTestimonial()

  if (isLoading) {
    return null
  }

  return (
    <div className="relative">
      <Loader isLoading={isLoading} />
      <TestimonialSlider {...props} />
    </div>
  )
}

export default TestimonialSliderWrapper
